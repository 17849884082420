import React, { FC } from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import {
  useInspectionsVehiclesNotes,
  useInspectionsVehiclesMechanicalsNotes,
} from '@/api/Inspections';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useTranslate } from '@/translations';

import { CustomCard } from '@/ui/components/CustomCard';
import { LightBoxItem } from '@/ui/components/ImagesLightBox';
import { ReplaceImageType } from '@/utils/constants';
import { AppRoles } from '@/types';
import { useIdTokenClaims } from '@/hooks';
import { InspectionTabCard } from '../../InspectionTabCard';
import { InspectionPageTab } from '../types';

export const Inspection: FC<InspectionPageTab> = ({ inspection }) => {
  const { t } = useTranslate();
  const { id } = useParams();
  const idTokenClaims = useIdTokenClaims();
  const inspectionNotes = useInspectionsVehiclesNotes(Number(id));
  const mechanicalsNotes = useInspectionsVehiclesMechanicalsNotes(Number(id));

  if (
    inspectionNotes.isLoading ||
    mechanicalsNotes.isLoading ||
    !inspectionNotes.data ||
    !mechanicalsNotes.data
  )
    return <Loader />;

  const { id: _inspectionNoteId, ...inspectionNote } =
    inspectionNotes.data.entities[0] || {};
  const { id: _mechanicalNoteId, ...mechanicalNote } =
    mechanicalsNotes.data.entities[0] || {};

  return (
    <PageContainer>
      <Row gutter={16}>
        <Col xs={24} md={12} lg={8}>
          <div className="fw-semibold mb-3">{t('odometer')}</div>
          <CustomCard>
            {inspection.mileage} {t('miles')}
            <Gallery>
              <LightBoxItem
                image={{
                  imageFileUrl: inspection.odometerImageFileName,
                  thumbnailImageUrl: inspection.odometerResizedImageUrl,
                }}
                replaceImage={
                  idTokenClaims?.roles.includes(AppRoles.GbaSuperAdmin)
                    ? {
                        type: ReplaceImageType.Odometer,
                        id: inspection.id,
                      }
                    : undefined
                }
              />
            </Gallery>
          </CustomCard>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <InspectionTabCard
            header={t('inspectionNotes')}
            data={inspectionNote}
            noDataMessage={t('noNotes')}
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <InspectionTabCard
            header={t('mechanicalInspectionNotes')}
            data={mechanicalNote}
            noDataMessage={t('noNotes')}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};
