import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';

import { Row } from 'antd';
import { Gallery } from 'react-photoswipe-gallery';
import { ReplaceImageType } from '@/utils/constants';
import { TireCard } from './components';
import { splitTireData } from './components/helpers';
import { InspectionPageTab } from '../types';

export const WheelsAndTyres: FC<InspectionPageTab> = ({ inspection }) => {
  const { frontLeft, frontRight, rearLeft, rearRight, spareTyre } =
    splitTireData(inspection);

  return (
    <PageContainer>
      <Gallery>
        <Row gutter={16}>
          <TireCard
            tireData={frontLeft}
            inspectionId={inspection.id}
            imageType={ReplaceImageType.WheelsAndTyresFrontLeft}
          />
          <TireCard
            tireData={frontRight}
            inspectionId={inspection.id}
            imageType={ReplaceImageType.WheelsAndTyresFrontRight}
          />
          <TireCard
            tireData={rearLeft}
            inspectionId={inspection.id}
            imageType={ReplaceImageType.WheelsAndTyresRearLeft}
          />
          <TireCard
            tireData={rearRight}
            inspectionId={inspection.id}
            imageType={ReplaceImageType.WheelsAndTyresRearRight}
          />
          <TireCard
            tireData={spareTyre}
            inspectionId={inspection.id}
            imageType={ReplaceImageType.WheelsAndTyresSpare}
          />
        </Row>
      </Gallery>
    </PageContainer>
  );
};
