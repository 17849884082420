/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import { acceptImageFiles } from '@/modules/UploadFile/utils';
import { useReplaceImage } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { ImagesLightBoxProps, LightBoxItemProps } from './types';
import { UploadButton } from '../UploadButton';

export const LightBoxItem: FC<LightBoxItemProps> = ({
  image,
  className,
  style,
  replaceImage,
  showNoImage,
  footer,
}) => {
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const { mutateAsync: replaceImageAsync } = useReplaceImage();
  const hasImage = image.thumbnailImageUrl && image.thumbnailImageUrl.length;

  const onGetBase64 = async (base64String: string, fileName: string) => {
    if (!replaceImage) return;

    await replaceImageAsync({
      id: replaceImage.id,
      imageType: replaceImage.type,
      imageFileName: fileName,
      imageFileBase64: base64String,
    });
    replaceImage.onReplacedImage?.();
  };

  return hasImage ? (
    <Item<HTMLElement>
      original={image.imageFileUrl}
      thumbnail={image.thumbnailImageUrl}
      alt={image.id?.toString() || ''}
      width={(dimensions?.width || 20) * 5}
      height={(dimensions?.height || 20) * 5}
    >
      {({ ref, open }) => (
        <div className="text-center">
          <div className="text-end" style={{ height: 0 }}>
            {replaceImage ? (
              <UploadButton
                tooltip="Click to replace image"
                accept={acceptImageFiles}
                onGetBase64={onGetBase64}
              />
            ) : null}
          </div>
          <button
            ref={ref}
            onClick={open}
            style={{
              border: 'none',
              background: 'none',
              padding: 0,
            }}
          >
            <img
              className={`rounded mw-100${className ? ` ${className}` : ''}`}
              style={style}
              src={image.thumbnailImageUrl}
              loading="lazy"
              alt=""
              onLoad={({ currentTarget }) =>
                setDimensions({
                  width: currentTarget.width,
                  height: currentTarget.height,
                })
              }
              onError={({ currentTarget }) => {
                // prevents looping
                currentTarget.onerror = null;
                // reset error image
                if (image.thumbnailImageUrl && image.thumbnailImageUrl.length) {
                  currentTarget.src = 'image-error.png';
                  currentTarget.alt = 'Failed to load image';
                }
              }}
            />
          </button>
          {footer?.()}
        </div>
      )}
    </Item>
  ) : showNoImage ? (
    <div
      className="d-flex align-items-center justify-content-center bg-secondary bg-gradient"
      style={{ width: 200, height: 250, ...(style || {}) }}
    >
      No Image
    </div>
  ) : null;
};

export const ImagesLightBox: FC<ImagesLightBoxProps> = ({
  images,
  className,
  style,
}) => (
  <Gallery>
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gridGap: '12px',
        ...style,
      }}
    >
      {images.map((image, idx) => (
        <div key={(idx + 1).toString()}>
          <LightBoxItem {...image} />
        </div>
      ))}
    </div>
  </Gallery>
);
