export const BILL_OF_LADING = (movementId: number) =>
  `order/${movementId}/billOfLading`;
export const COMPANIES = () => 'companies';
export const COMPANY_TYPES = () => 'companyTypes';
export const COUNTRIES = () => 'countries';
export const DOCUMENT_MANAGEMENT_DOCUMENT_TYPES = () =>
  'DocumentManagement/documentType';
export const DOCUMENT_MANAGEMENT_DOCUMENT_TYPE = (documentTypeId: number) =>
  `DocumentManagement/documentType/${documentTypeId}`;
export const DOCUMENT_MANAGEMENT_DOCUMENTS = () =>
  'DocumentManagement/document';
export const DOCUMENT_MANAGEMENT_DOCUMENT = (documentId: number) =>
  `DocumentManagement/document/${documentId}`;
export const FUNCTIONAL_FEATURES = () => 'functionalFeatures';
export const GBA_USERS = (userId?: number) =>
  userId ? `user/gba/${userId}` : 'user/gba';
export const INSPECTIONS_REPORTS_INITIAL = (inspectionId: number) =>
  `Inspections/reports/initial?inspectionId=${inspectionId}`;
export const INSPECTIONS_VEHICLES = () => 'Inspections/vehicles';
export const INSPECTIONS_VEHICLES_FILTERS = () =>
  'Inspections/vehicles/filters';
export const INSPECTIONS_VEHICLE = (id: number) => `Inspections/vehicles/${id}`;
export const MECHANICAL_INSPECTION = (barCode: string | number) =>
  `MechanicalInspection/${barCode}`;
export const MECHANICAL_INSPECTION_AVAILABLE = (barCode: string | number) =>
  `MechanicalInspection/${barCode}/available`;
export const INSPECTIONS_VEHICLE_DAMAGE = () => `Inspections/vehicles/damages`;
export const INSPECTIONS_VEHICLE_DAMAGE_STATUS = () =>
  `Inspections/vehicles/damages/status`;
export const INSPECTIONS_VEHICLE_SEVERITY_ACTIONS = (id: number) =>
  `Inspections/severities?inspectionId=${id}`;
export const INSPECTIONS_VEHICLE_REPAIR_ACTIONS = () =>
  `Inspections/vehicles/actions`;
export const INSPECTIONS_VEHICLES_ID_DAMAGES_LINES = (id: number) =>
  `Inspections/vehicles/${id}/damages/lines`;

export const GET_DAMAGES_LINES = () => `Inspections/vehicles/damages/lines`;
export const UPDATE_DAMAGES_LINES = (damageLineId: number) =>
  `Inspections/vehicles/damages/lines/${damageLineId}`;
export const DELETE_DAMAGES_LINES = (
  damageLineId: number,
  inspectionId: number,
) => `Inspections/vehicles/damages/lines/${damageLineId}/${inspectionId}`;
export const UN_DELETE_DAMAGES_LINES = (
  damageLineId: number,
  inspectionId: number,
) =>
  `Inspections/vehicles/damages/lines/undelete/${damageLineId}/${inspectionId}`;

export const INSPECTIONS_VEHICLE_CONDITIONS = () =>
  `Inspections/vehicles/conditions`;
export const INSPECTIONS_VEHICLE_DAMAGE_PARTS_BY_ID = (id: number) =>
  `Inspections/vehicles/damages/${id}/parts`;

export const INSPECTIONS_VEHICLE_DAMAGE_PARTS = () =>
  'Inspections/vehicles/damages/parts';

export const INSPECTIONS_VEHICLE_DAMAGE_ACTIONS = () =>
  'Inspections/vehicles/damages/actions';
export const INSPECTIONS_VEHICLE_DAMAGE_ACTIONS_BY_ID = (id: number) =>
  `Inspections/vehicles/damages/${id}/actions`;
export const INSPECTIONS_VEHICLE_DAMAGES_CODES_SITE_PART = (
  siteId: string | number,
) => `Inspections/vehicles/damages/codes/${siteId}`;
export const INSPECTIONS_VEHICLE_DAMAGES_CONDITION_CODES_SITE_PART = (
  partCode: string | number,
) => `Inspections/vehicles/damages/condition/codes/${partCode}`;
export const INSPECTIONS_VEHICLES_PARTS = (partNumber: string) =>
  `Inspections/vehicles/parts?partNumber=${partNumber}`;
export const INSPECTIONS_VEHICLES_DAMAGE_ACTIONS_BY_FLAT_RATE_CODE = (
  frCode: string,
) => `Inspections/vehicles/rates?flatRateCode=${frCode}`;
export const INSPECTIONS_CREATE_FLAT_RATE_CODE = () =>
  `/Inspections/vehicles/rates`;

export const DELETE_DAMAGE_PART = (
  partNumberId: string,
  inspectionId: number,
) => `Inspections/vehicles/damages/parts/${partNumberId}/${inspectionId}`;

export const DELETE_DAMAGE_ACTION = (
  damageActionId: string,
  inspectionId: number,
) => `Inspections/vehicles/damages/actions/${damageActionId}/${inspectionId}`;

export const INSPECTIONS_VEHICLES_INSPECTORS = () =>
  `/Inspections/vehicles/inspectors`;
export const INSPECTIONS_VEHICLES_INSPECTORS_ID = (id: number) =>
  `/Inspections/vehicles/inspectors/${id}`;
export const INSPECTIONS_VEHICLES_ID_NOTES = (id: number) =>
  `Inspections/vehicles/${id}/notes`;
export const INSPECTIONS_VEHICLES_SUMMARIES = (id: number) =>
  `Inspections/vehicles/${id}/summaries`;
export const INSPECTIONS_VEHICLES_NOTES = () => `Inspections/vehicles/notes`;
export const INSPECTIONS_VEHICLES_MECHANICALS_NOTES = (id: number) =>
  `Inspections/mechanicals/${id}/notes`;
export const INSPECTIONS_MECHANICALS_WHEELS = (id: number) =>
  `Inspections/mechanicals/${id}/wheels`;
export const INSPECTIONS_LOGS = (id: number) => `Inspections/logs/${id}`;
export const INSPECTIONS_MOT = (id: number) => `Inspections/MOT/${id}`;
export const INSPECTIONS_CHECKLIST_DYNAMIC_OPERATIONS = (id: number) =>
  `Inspections/${id}/checklist/ista`;
export const INSPECTIONS_CHECKLIST_ELECTRIC_HYBRID = (id: number) =>
  `Inspections/${id}/checklist/electric`;
export const INSPECTIONS_CHECKLIST_ENGINE_RUNNING = (id: number) =>
  `Inspections/${id}/checklist/enginerunning`;
export const INSPECTIONS_CHECKLIST_INTERIOR = (id: number) =>
  `Inspections/${id}/checklist/interior`;
export const INSPECTION_DAMAGES_IMAGES = (id?: number) =>
  id ? `Inspections/damages/${id}/images` : 'Inspections/damages/images';
export const INSPECTION_DAMAGES_IMAGES_ID = (id: number) =>
  `Inspections/damages/images/${id}`;
export const INSPECTION_TNROVERRIDE = () => `Inspections/TNROverride`;
export const INSPECTION_GETTNRDETAILS = (id: number) =>
  `Inspections/TNROverride/${id}`;
export const INSPECTION_REPLACE_IMAGE = () => `Inspections/replaceImage`;
export const LOCATIONS = () => 'locations';
export const LOCATIONS_SITES = (tenantId: number) =>
  `Locations/sites/${tenantId}`;
export const LOCATION_TYPES = () => 'locationTypes';
export const ORDER_SUMMARY = () => 'order/summary';
export const PASSPORTS = () => `passports`;
export const PASSPORTS_TRANSACTIONS = () => `passports/transactions`;
export const PIPELINES_LOCATIONS = () => 'pipelines/locations';
export const PIPELINES_GRAPH = () => 'pipelines/graph';
export const PIPELINES_STATUS = (locationId?: string) =>
  locationId ? `pipelines/status?locationId=${locationId}` : 'pipelines/status';
export const PIPELINES = (locationId?: string) =>
  locationId ? `pipelines?locationId=${locationId}` : 'pipelines';
export const PTS = () => 'pts';
export const ROLES = () => 'roles';
export const SERVICE_PROVIDERS = () => 'serviceProviders';
export const SERVICE_UNITS = () => 'serviceUnits';
export const SERVICE_UNIT_TYPES = () => 'serviceUnitTypes';
export const STORAGE = () => 'storage';
export const STORAGE_GRAPH = () => 'storage/graph';
export const SHIPPING_PLAN = (documentId?: number) =>
  documentId ? `shippingPlan/${documentId}` : 'shippingPlan';
export const SHIPPING_PLANS = (tenantId?: string) =>
  tenantId ? `shippingPlan/${tenantId}` : 'shippingPlan';
export const TENANTS = (tenantId?: number) =>
  tenantId ? `tenant/${tenantId}` : 'tenant';
export const TENANT_BRANDING = () => 'tenant/branding';
export const TRANSPORT_MOVEMENTS = () => 'transport/movements';
export const TRANSPORT_POSITIONS = () => 'transport/positions';
export const USERS = (userId?: number) => (userId ? `user/${userId}` : 'user');
export const VESSEL_MOVEMENTS = () => 'vessel/movements';
export const VESSEL_POSITIONS = () => 'vessel/positions';
export const VIN_MOVEMENTS = () => 'vin/movements';
export const VIN_POSITIONS = (vin?: string) =>
  vin ? `vin/positions?vin=${vin}` : 'vin/positions';
export const TMS_USERS = (tmsUserId?: number) =>
  tmsUserId ? `user/tms/${tmsUserId}` : 'user/tms';
