import React, { FC } from 'react';
import {
  useInspectionsVehicleRepairActions,
  useInspectionsVehiclesDamagesLines,
  useInspectionsVehicleSeverityActions,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { HUB_METHODS_NAMES, useWebSockets } from '@/hooks';
import { DamageDataGrid } from './components/DamageDataGrid';
import { InspectionPageTab } from '../types';

export const Damage: FC<InspectionPageTab> = ({ inspection, onReload }) => {
  const { id } = inspection;

  const {
    isLoading,
    data: damagesData,
    refetch: damagesRefech,
  } = useInspectionsVehiclesDamagesLines(id);

  const {
    data: repairActionsData,
    isLoading: repairLoading,
    refetch: repairRefetch,
  } = useInspectionsVehicleRepairActions();
  const {
    data: severityActionsData,
    isLoading: severityLoading,
    refetch: severityRefetch,
  } = useInspectionsVehicleSeverityActions(id);

  const onReloadData = () => {
    damagesRefech();
    repairRefetch();
    severityRefetch();
  };

  const wsEventHandler = (data: number) => {
    if (data === id) {
      console.log('refetch damages trigged by ws');
      onReloadData();
    }
  };

  useWebSockets(HUB_METHODS_NAMES.SendVehicleInspectionsToUser, wsEventHandler);

  const dataLoaded = repairActionsData && severityActionsData && damagesData;

  return (
    <DamageDataGrid
      loading={isLoading || repairLoading || severityLoading}
      inspection={inspection}
      dataSource={dataLoaded ? damagesData?.entities : []}
      repairActionsData={repairActionsData?.entities}
      severityActionsData={severityActionsData}
      onReload={() => {
        onReload?.();
        onReloadData();
      }}
    />
  );
};
