import { useTranslate } from '@/translations';
import { TranslationKey } from '@/translations/types';
import React from 'react';
import { Col, Descriptions } from 'antd';
import { CustomCard } from '@/ui/components/CustomCard';
import { LightBoxItem } from '@/ui/components/ImagesLightBox';
import { ReplaceImageType } from '@/utils/constants';
import { useIdTokenClaims } from '@/hooks';
import { AppRoles } from '@/types';
import { TireGroup } from './types';

export const TireCard = ({
  tireData,
  inspectionId,
  imageType,
}: {
  tireData: TireGroup;
  inspectionId: number;
  imageType: ReplaceImageType;
}) => {
  const { t } = useTranslate();
  const idTokenClaims = useIdTokenClaims();

  const ignoredKeys = ['imageFileUrl', 'thumbnailImageUrl'];

  return (
    <Col xs={12} md={8} lg={6} className="mb-3">
      <CustomCard direction="column">
        <Descriptions
          column={1}
          bordered
          size="small"
          className="no-border"
          items={Object.entries(tireData)
            .filter(([key]) => !ignoredKeys.includes(key))
            .map(([key, value]) => ({
              key,
              label: `${t(key as TranslationKey)}:`,
              children: value || t('notApplicable'),
            }))}
        />

        {tireData?.imageFileUrl ? (
          <LightBoxItem
            image={{
              imageFileUrl: tireData.imageFileUrl,
              thumbnailImageUrl: tireData.thumbnailImageUrl,
            }}
            replaceImage={
              idTokenClaims?.roles.includes(AppRoles.GbaSuperAdmin)
                ? {
                    type: imageType,
                    id: inspectionId,
                  }
                : undefined
            }
          />
        ) : null}
      </CustomCard>
    </Col>
  );
};
