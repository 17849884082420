import React, { ChangeEvent, FC, useRef, useState } from 'react';
import type { UploadProps } from 'antd';
import { Button, message, Tooltip } from 'antd';
import { faUpload, Icon } from '../Icon';

interface UploadButtonProps {
  text?: string;
  tooltip?: string;
  className?: string;
  style?: React.CSSProperties;
  accept?: UploadProps['accept'];
  maxFileSize?: number;
  invalidMaxFileSizeMessage?: string;
  onUpload?: (file: File) => Promise<void>;
  onGetBase64?: (base64String: string, fileName: string) => Promise<void>;
  onFinished?: () => void;
}

export const UploadButton: FC<UploadButtonProps> = ({
  text,
  tooltip,
  className,
  style,
  accept,
  maxFileSize,
  invalidMaxFileSizeMessage,
  onUpload,
  onGetBase64,
  onFinished,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList || fileList.length === 0) return;

    const file = fileList.item(0);
    if (!file) return;

    if (maxFileSize) {
      const invalidFiles = !file.size || file.size > maxFileSize;
      if (invalidFiles) {
        const msg = invalidMaxFileSizeMessage || 'File is too large';
        message.error(msg);
        return;
      }
    }

    setUploading(true);
    await onUpload?.(file);
    if (onGetBase64) {
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        if (target?.result) {
          await onGetBase64(target.result as string, file.name);
        }
        setUploading(false);
        onFinished?.();
      };
      reader.readAsDataURL(file);
    } else {
      setUploading(false);
      onFinished?.();
    }
  };

  const ButtonUI = (
    <Button
      shape={text ? undefined : 'circle'}
      className={className}
      style={{ zIndex: 2, ...(style || {}) }}
      loading={uploading}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current?.click();
      }}
    >
      {!uploading ? <Icon icon={faUpload} /> : null}
      {text}
    </Button>
  );

  return (
    <div className="d-inline-block">
      {tooltip && tooltip.length ? (
        <Tooltip title={tooltip}>{ButtonUI}</Tooltip>
      ) : (
        ButtonUI
      )}
      <input
        onChange={handleChange}
        accept={accept}
        multiple={false}
        ref={fileInputRef}
        type="file"
        hidden
      />
    </div>
  );
};
